import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Dash,
  ExclamationCircleFill,
  FileEarmark,
} from 'react-bootstrap-icons';

import SiteTableDisplay from './SiteTableDisplay';
import TableLayout from 'components/Layouts/TableLayout';
import PatientStudyTableDisplay from 'components/PatientStudyTableDisplay';
import RecentNoteIcon from 'components/RecentNoteIcon';

const renderStudiesCell = (props) => (
  <PatientStudyTableDisplay
    cellDisplay={props.getValue()}
    patientStudies={props.row.original.patientStudies?.filter(
      (ps) => !ps.study.is_registry
    )}
  />
);

const renderSitesCell = (props) => (
  <SiteTableDisplay
    cellDisplay={props.getValue()}
    sites={props.row.original.sites}
  />
);

const viewPatient = (patientId) => {
  window.open(`/patients/${patientId}`);
};

const getRowProps = (row) => ({
  className: 'hoverable-row',
  onClick: () => viewPatient(row.original.id),
});

const COLUMNS = [
  {
    accessorKey: 'fullName',
    header: 'Name',
    meta: {
      headerProps: { className: 'display-4 fw-bold text-uppercase' },
    },
    cell: (props) => {
      if (!props.row.original.doNotContact) return props.getValue();

      return (
        <div className="d-flex align-items-center">
          <div className="me-2">{props.getValue()}</div>
          <OverlayTrigger
            overlay={<Tooltip>Do Not Contact</Tooltip>}
            placement="top"
          >
            <ExclamationCircleFill className="text-danger" />
          </OverlayTrigger>
        </div>
      );
    },
  },
  {
    accessorKey: 'medicalRecordNumber',
    header: 'MRN',
    meta: {
      headerProps: {
        className: 'display-4 fw-bold text-uppercase',
        style: { width: '15%' },
      },
    },
  },
  {
    id: 'sites.name',
    // It's evaluated only when if renderSitesCell is used.
    accessorFn: (row) => `${row.sites.length} Sites`,
    header: 'Site',
    meta: {
      headerProps: { className: 'display-4 fw-bold text-uppercase' },
    },
    cell: (props) => {
      if (props.row.original.sites?.length > 1) {
        return renderSitesCell(props);
      }

      if (props.row.original.sites?.length === 1) {
        return <span>{props.row.original.sites[0].name}</span>;
      }

      return <Dash />;
    },
  },
  {
    accessorKey: 'nextAppointment',
    header: 'Appt',
    meta: {
      headerProps: {
        className: 'display-4 fw-bold text-uppercase',
        style: { width: '15%' },
      },
    },
    cell: (props) => {
      const {
        nextAppointment: appointment,
        appointmentReason,
        sites,
      } = props.row.original;

      if (appointment) {
        // TODO: It's not ideal, and probably error-prone. We need to return appointment's site...
        const timeZone = sites[0].zone.name;
        const formattedDate = DateTime.fromISO(appointment, {
          zone: timeZone,
        }).toFormat('M/d/yy t ZZZZ');

        return (
          <div>
            <div>{formattedDate}</div>
            <div className="text-muted">{appointmentReason}</div>
          </div>
        );
      }
      return <Dash />;
    },
  },
  {
    id: 'patientStudies.study.name',
    // It's evaluated only when if renderStudiesCell is used.
    accessorFn: (row) =>
      `${
        row.patientStudies.filter((ps) => !ps.study.is_registry).length
      } Studies`,
    header: 'Study',
    meta: {
      headerProps: { className: 'display-4 fw-bold text-uppercase' },
    },
    cell: (props) => {
      const nonRegistryStudies = props.row.original.patientStudies?.filter(
        (ps) => !ps.study.is_registry
      );

      if (nonRegistryStudies?.length > 1) {
        return renderStudiesCell(props);
      }

      if (nonRegistryStudies?.length === 1) {
        return <span>{nonRegistryStudies[0].study.name}</span>;
      }

      return <Dash />;
    },
  },
  {
    accessorKey: 'remarks',
    header: () => <FileEarmark />,
    enableSorting: false,
    meta: {
      headerProps: { className: 'text-center', style: { width: '32px' } },
      cellProps: { className: 'text-center' },
    },
    cell: (props) => <RecentNoteIcon show id={props.row.original.id} />,
  },
];

export default function SearchResultsTable({
  data,
  pagination,
  setPagination,
  sorting,
  setSorting,
}) {
  if (!data?.rows) {
    return (
      <Card>
        <Card.Body>No Results found</Card.Body>
      </Card>
    );
  }

  return (
    <TableLayout
      columns={COLUMNS}
      data={data}
      pagination={pagination}
      setPagination={setPagination}
      sorting={sorting}
      setSorting={setSorting}
      getRowProps={getRowProps}
    />
  );
}

SearchResultsTable.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setPagination: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  sorting: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SearchResultsTable.defaultProps = {
  data: undefined,
};
