import PropTypes from 'prop-types';
import React from 'react';
import {
  OverlayTrigger,
  Popover,
  PopoverHeader,
  PopoverBody,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';

/** @type {React.ForwardRefExoticComponent<any, any>} */
const SiteTableDisplay = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ cellDisplay, sites }, ref) => {
    if (!cellDisplay) {
      return <Dash />;
    }

    return (
      <OverlayTrigger
        overlay={
          <Popover>
            <PopoverHeader>Sites</PopoverHeader>
            <PopoverBody className="p-1">
              <ListGroup variant="flush">
                {sites.map((site) => (
                  <ListGroupItem key={`site-${site.id}`}>
                    {site.name}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </PopoverBody>
          </Popover>
        }
      >
        <span style={{ cursor: 'pointer' }} className="user-select-none">
          {cellDisplay}
        </span>
      </OverlayTrigger>
    );
  }
);

export default SiteTableDisplay;

SiteTableDisplay.defaultProps = {
  sites: [],
  cellDisplay: '',
};

SiteTableDisplay.propTypes = {
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  cellDisplay: PropTypes.string,
};
