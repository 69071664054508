import React, { useState } from 'react';
import { Tab, Nav, Stack } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import AdminSiteDetailsSettingsTab from './components/AdminSiteDetailsSettingsTab';
import SiteConnectionsList from './components/SiteConnectionsList';
import SiteIndicationList from './components/SiteIndicationList';
import SiteInfo from './components/SiteInfo';
import SiteStudyList from './components/SiteStudyList';
import StudyAdd from './components/StudyAdd';
import SiteUsersList from './components/UserSiteList';

import SiteModal from '../components/SiteModal';

import NavigateBack from 'components/Button/NavigateBack';
import LoadingIndicator from 'components/LoadingIndicator';
import IndicationAdd from 'components/Management/SiteIndications/IndicationAdd';
import UserSearchAdd from 'components/UserSearchAdd';
import { useSite } from 'hooks/siteHooks';

function SiteDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const siteIdParam = Number(params.id);

  const [showSiteModal, setShowSiteModal] = useState(false);

  const { data: site, isLoading } = useSite(siteIdParam);

  if (isLoading) return <LoadingIndicator />;

  return (
    <Stack gap={2}>
      {location.state?.backPath && (
        <NavigateBack
          onClick={() => navigate(location.state.backPath)}
          text="Back to My Organization"
        />
      )}

      <SiteInfo site={site} setShowSiteModal={setShowSiteModal} />

      <div>
        <Tab.Container defaultActiveKey="settings">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="settings">Settings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="users">Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="studies">Studies</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="connections">Connections</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="indications">Indications</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="settings">
              <AdminSiteDetailsSettingsTab site={site} />
            </Tab.Pane>
            <Tab.Pane eventKey="users">
              <Stack gap={2} className="pt-2">
                <UserSearchAdd
                  siteId={site.id}
                  organizationId={site.organizationId}
                />
                <SiteUsersList siteId={siteIdParam} />
              </Stack>
            </Tab.Pane>
            <Tab.Pane eventKey="studies">
              <Stack gap={3} className="pt-2">
                <StudyAdd site={site} />
                <SiteStudyList siteId={siteIdParam} />
              </Stack>
            </Tab.Pane>
            <Tab.Pane eventKey="connections">
              <Stack gap={3} className="pt-2">
                <SiteConnectionsList siteId={siteIdParam} />
              </Stack>
            </Tab.Pane>
            <Tab.Pane eventKey="indications">
              <Stack gap={3} className="pt-2">
                <IndicationAdd siteId={siteIdParam} />
                <SiteIndicationList siteId={siteIdParam} />
              </Stack>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      {showSiteModal && (
        <SiteModal site={site} setShowSiteModal={setShowSiteModal} />
      )}
    </Stack>
  );
}

export default SiteDetails;
