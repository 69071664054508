import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Card, Col, DropdownButton, Row, Dropdown } from 'react-bootstrap';
import { Pencil, Trash, Check2Circle, XCircle } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import ConfirmationDialog from 'components/ConfirmationDialog';
import CheckField from 'components/FormikForm/CheckField';
import TextField from 'components/FormikForm/TextField';
import ModalForm from 'components/ModalForm';
import PatientDemographicsModal from 'components/Patient/PatientDemographicsModal';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import {
  useDeletePatient,
  useUpdatePatientDoNotContact,
} from 'hooks/patientHooks';
import { formatCityWithState } from 'pages/utils/addressUtils';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';
import { formatPhoneDisplayV2 } from 'pages/utils/phoneUtils';

const DO_NOT_CONTACT_SCHEMA = yup.object({
  doNotContact: yup.boolean().nullable(),
  doNotContactReason: yup.string().when('doNotContact', {
    is: (val) => val === true,
    then: (schema) => schema.required(),
  }),
});

export default function PatientDemographics({ patient }) {
  const { userRole } = useContext(UserContext);
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDoNotContactModal, setShowDoNotContactModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const demographics = [
    {
      leftLabel: 'DOB',
      leftValue: formatDateDisplay(patient.birthDate),
      rightLabel: 'Phone',
      rightValue: formatPhoneDisplayV2(patient.homePhone),
    },
    {
      leftLabel: 'Gender',
      leftValue: patient.gender?.name ?? '-',
      rightLabel: 'Cell',
      rightValue: formatPhoneDisplayV2(patient.mobilePhone),
    },
    {
      leftLabel: 'Ethnicity',
      leftValue: patient.ethnicGroup?.name ?? '-',
      rightLabel: 'Address',
      rightValue: patient.address ?? '-',
    },
    {
      leftLabel: 'Race',
      leftValue: patient.race?.name ?? '-',
      rightLabel: 'City/State',
      rightValue: formatCityWithState(patient.city, patient.state, '-'),
    },
    {
      leftLabel: 'Email',
      leftValue: patient.email?.toLowerCase(),
      rightLabel: 'Zip Code',
      rightValue: patient.postalCode ?? '-',
    },
  ];

  const deletePatient = useDeletePatient({
    onSuccess: () => {
      setShowDeleteDialog(false);
      navigate('/', { replace: true });
    },
  });

  const updatePatientDoNotContact = useUpdatePatientDoNotContact({
    onSuccess: () => {
      setShowDoNotContactModal(false);
    },
  });

  const handleDelete = () => {
    deletePatient.mutate({ id: patient.id });
  };

  const handleDoNotContact = (values) => {
    updatePatientDoNotContact.mutate({
      id: values.id,
      doNotContact: values.doNotContact,
      doNotContactReason: values.doNotContact
        ? values.doNotContactReason
        : null,
    });
  };

  return (
    <>
      <h5 className="display-5">Patient Card</h5>
      <Card id="patient-demographics" className="rounded">
        <Card.Header className="bg-white border-bottom border-light">
          <Row>
            <Col className="d-flex align-items-center">
              <div className="opacity-50">MRN #</div>
              &nbsp;
              <div className="fs-3 ms-1">{patient.medicalRecordNumber}</div>
              <DropdownButton
                title="Actions"
                className="ms-auto"
                variant="outline-primary"
                disabled={
                  !Roles.canModifyRecords(userRole) || deletePatient.isLoading
                }
                size="sm"
              >
                <Dropdown.Item onClick={() => setShowEditModal(true)}>
                  <Pencil />
                  &nbsp; Edit
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  className={`text-${
                    patient.doNotContact ? 'success' : 'danger'
                  }`}
                  onClick={() => setShowDoNotContactModal(true)}
                >
                  {patient.doNotContact ? <Check2Circle /> : <XCircle />}
                  &nbsp;{' '}
                  <span>
                    {patient.doNotContact ? 'Allow' : 'Do Not'} Contact
                  </span>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setShowDeleteDialog(true)}>
                  <Trash />
                  &nbsp; Delete
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="d-grid gap-4 pt-4">
          {demographics.map(
            ({ leftLabel, leftValue, rightLabel, rightValue }, index) => (
              <Row
                key={leftLabel}
                className={`${
                  index !== demographics.length - 1
                    ? `border-bottom  border-lighter pb-2`
                    : ''
                }`}
              >
                <Col>
                  <small className="text-uppercase opacity-50">
                    {leftLabel}
                  </small>
                  <span className="ms-3">{leftValue}</span>
                </Col>
                {rightLabel && (
                  <Col>
                    <small className="text-uppercase opacity-50">
                      {rightLabel}
                    </small>
                    <span className="ms-3">{rightValue}</span>
                  </Col>
                )}
              </Row>
            )
          )}
        </Card.Body>
      </Card>
      {showEditModal && (
        <PatientDemographicsModal
          show
          onHide={() => setShowEditModal(false)}
          patient={{
            ...patient,
            siteIds: patient.sites.map((site) => site.id),
          }}
        />
      )}
      {showDoNotContactModal && (
        <ModalForm
          title={patient.doNotContact ? 'Allow Contact' : 'Do Not Contact'}
          onClose={() => setShowDoNotContactModal(false)}
          isSubmitting={updatePatientDoNotContact.isLoading}
          initialValues={patient}
          validationSchema={DO_NOT_CONTACT_SCHEMA}
          formId="patient-do-not-contact"
          onSubmit={handleDoNotContact}
        >
          <CheckField name="doNotContact" label="Do Not Contact" />

          <TextField
            name="doNotContactReason"
            placeholder="Reason"
            controlClassName="w-75"
          />
        </ModalForm>
      )}
      <ConfirmationDialog
        show={showDeleteDialog}
        title="Confirm Delete"
        bodyText="Are you sure you want to delete this patient?"
        confirmText="Delete"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </>
  );
}

PatientDemographics.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    medicalRecordNumber: PropTypes.string.isRequired,
    email: PropTypes.string,
    birthDate: PropTypes.string,
    mobilePhone: PropTypes.string,
    homePhone: PropTypes.string,
    postalCode: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    nextAppointment: PropTypes.string,
    appointmentReason: PropTypes.string,
    appointmentProviderLastName: PropTypes.string,
    appointmentProviderFirstName: PropTypes.string,
    doNotContact: PropTypes.bool,
    ethnicGroup: PropTypes.shape({
      name: PropTypes.string,
    }),
    race: PropTypes.shape({
      name: PropTypes.string,
    }),
    site: PropTypes.shape({
      name: PropTypes.string,
    }),
    sites: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number.isRequired })
    ),
    gender: PropTypes.shape({
      name: PropTypes.string,
    }),
    createdBy: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }).isRequired,
};
