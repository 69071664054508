export default Object.freeze([
  { value: 10, label: 'Within 10 miles' },
  { value: 20, label: 'Within 20 miles' },
  { value: 30, label: 'Within 30 miles (default)' },
  { value: 40, label: 'Within 40 miles' },
  { value: 50, label: 'Within 50 miles' },
  { value: 60, label: 'Within 60 miles' },
  { value: 9999, label: 'Over 60 Miles' },
  { value: -1, label: 'Show All' },
]);
